// Bootstrap imports
import Alert from 'bootstrap/js/dist/alert';
import Popover from 'bootstrap/js/dist/popover';

/**
 * Dismiss alerts automatically after 3 seconds
 */
export function initAutoDismissAlerts() {
  const alertList = document.querySelectorAll('.alert-dismissible');
  alertList.forEach(function (alert) {
    setTimeout(function () {
      const bsAlert = Alert.getOrCreateInstance(alert);
      if (bsAlert !== null) {
        bsAlert.close();
      }
    }, 3000);
  });
}

/**
 * Initialize Bootstrap popovers
 */
export function initPopovers() {
  const popoverElements = document.querySelectorAll('[data-bs-toggle="popover"]');
  popoverElements.forEach((popoverElement) => {
    new Popover(popoverElement);
  });
}
