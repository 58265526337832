// Custom project styles
import '../sass/project.scss';

import Router from './router';
import { initAutoDismissAlerts } from './utils';
import { initTableFilter, initTableSort } from './tables';

document.addEventListener('DOMContentLoaded', async () => {
  // Initialize common functionality
  initAutoDismissAlerts();
  initTableSort();
  initTableFilter();

  // Initialize router
  await Router.init();
});
